import React from 'react'
import Helmet from 'react-helmet'
import Iframe from 'react-iframe'
import { Container } from 'reactstrap'
import Contactform from '../components/contactform'
import Layout from '../components/layout'

const ContactPage = () => {
  return (
    <Layout>
      <Helmet>
        <title>Επικοινωνία | Σπιτικό Παπακωνσταντίνου</title>
        <meta name="description" content="Επικοινωνήστε μαζί μας." />
      </Helmet>
      <Container className="contact">
        <h1>
          <b>Επικοινωνία</b>
        </h1>
        <Iframe
          url="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12103.579860101503!2d22.9433501!3d40.6762832!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xc52393ce39226681!2sSpitiko%20Papakonstantinou!5e0!3m2!1sen!2sgr!4v1673615471224!5m2!1sen!2sgr"
          height="350px"
          id="googleMap"
          className="mapContainer"
          display="initial"
          position="relative"
          allowFullScreen
        />
        <Contactform />
      </Container>
    </Layout>
  );
}

export default ContactPage