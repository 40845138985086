import React from 'react'
import { Container } from 'reactstrap'

const Contactform = ({ nutA, nutB, nutC, nutD, nutE, nutF, nutG, nutH }) => (
    <Container className="row">
    <div className="col-md-12">
        <form name="epikoinonia" id="epikoinonia" method="post" action="https://www.mpares.com/" data-netlify="true" data-netlify-honeypot="bot-field">
        <input type="hidden" name="form-name" value="epikoinonia" />  
        <p>
            <label>Όνομα:</label>   
        </p>
        <p><input type="text" name="name" /></p>
        <p>
            <label>Τηλέφωνο:</label>
        </p>
        <p><input type="phone" name="phone" /></p>
        <p>
            <label>Email:</label>
        </p>
        <p><input type="email" name="email" /></p>
        <p>
            <label>Μύνημα:</label>
        </p>
        <p><textarea name="message"></textarea></p>
        <p>
            <button type="submit">Αποστολή</button>
        </p>
        </form>
    </div>
    </Container>
)

export default Contactform